<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M29.3333 10.4132V18.5332L27.16 16.6665C26.12 15.7732 24.44 15.7732 23.4 16.6665L17.8533 21.4265C16.8133 22.3198 15.1333 22.3198 14.0933 21.4265L13.64 21.0532C12.6933 20.2265 11.1866 20.1465 10.12 20.8665L3.55996 25.2665L3.41329 25.3732C2.91996 24.3065 2.66663 23.0398 2.66663 21.5865V10.4132C2.66663 5.55984 5.55996 2.6665 10.4133 2.6665H21.5866C26.44 2.6665 29.3333 5.55984 29.3333 10.4132Z"
      fill="#CAA8F5"
    />
    <path
      d="M12 13.8398C13.7526 13.8398 15.1733 12.4191 15.1733 10.6665C15.1733 8.91391 13.7526 7.49316 12 7.49316C10.2474 7.49316 8.82666 8.91391 8.82666 10.6665C8.82666 12.4191 10.2474 13.8398 12 13.8398Z"
      fill="#CAA8F5"
    />
    <path
      d="M29.3333 18.5332V21.5866C29.3333 26.4399 26.44 29.3332 21.5867 29.3332H10.4133C7.01333 29.3332 4.56 27.9066 3.41333 25.3733L3.56 25.2666L10.12 20.8666C11.1867 20.1466 12.6933 20.2266 13.64 21.0532L14.0933 21.4266C15.1333 22.3199 16.8133 22.3199 17.8533 21.4266L23.4 16.6666C24.44 15.7732 26.12 15.7732 27.16 16.6666L29.3333 18.5332Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "GallerySvg",
};
</script>

<style scoped></style>
