<template>
  <div class="page-camera">
    <div class="page-camera__text">
      {{ $t("camera-top-text") }}
    </div>
    <div class="page-camera__wrap">
      <StreamBarcodeReader
        v-if="!disableScan && cameraAllowed"
        @decode="(a, b, c) => onDecode(a, b, c)"
      />
      <qrcode-capture
        @detect="onDetect"
        id="fileInput"
        :capture="false"
        style="display: none"
      />
    </div>

    <div class="page-camera__bottom">
      <div class="page-camera__btn" @click="openFileInput">
        <PageButton>
          <template v-slot:icon>
            <GallerySvg />
          </template>
        </PageButton>

        <span>{{ $t("gallery-btn") }}</span>
      </div>

      <div class="page-camera__btn" @click="goBack">
        <PageButton>
          <template v-slot:icon>
            <Close />
          </template>
        </PageButton>

        <span>{{ $t("close-btn") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import api from "@/api/api";
import GallerySvg from "../components/icons/GallerySvg";
import Close from "../components/icons/Close";
import PageButton from "../components/common/buttons/PageButton";
import ValidateCurrencyCardMixin from "@/mixins/cards/ValidateCurrencyCardMixin";
import GoBackMixin from "@/mixins/common/GoBackMixin";
import { mapActions, mapMutations } from "vuex";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import errors from "@/utils/errors";
import GetError from "@/mixins/general/GetError";
import { QrcodeCapture } from "vue-qrcode-reader-inverted";

export default {
  name: "QrReader",
  mixins: [
    ValidateCurrencyCardMixin,
    GoBackMixin,
    SetProcessingMixin,
    GetError,
  ],
  data: () => ({
    disableScan: false,
    cameraAllowed: false,
    needCameraAccess: false,
  }),
  watch: {
    getError(val) {
      this.disableScan = !!val;
      if (!val && this.needCameraAccess) {
        this.cameraAllowed = true;
        this.needCameraAccess = false;
      }
    },
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    ...mapMutations({
      setBalanceToppedUp: "wallet/setBalanceToppedUp",
    }),
    openFileInput() {
      const fileInput = document.getElementById("fileInput");
      fileInput.click();
    },
    async onDetect(promise) {
      try {
        const {
          content, // decoded String or null
        } = await promise;

        console.log(content);

        if (content === null) {
          this.setError(errors.QR_CODE_NOT_DETECTED);
        } else {
          await this.onDecode(content);
        }
      } catch (error) {
        this.setError(error);
      }
    },
    async onDecode(a) {
      try {
        if (this.disableScan) {
          return;
        }
        this.setLoading(true);
        this.disableScan = true;
        const url = new URL(a);
        let code = url.search.replace("?code=", "");
        const response = await this.validateCurrencyCard(
          encodeURIComponent(code)
        );
        if (response.isUsed) {
          this.setError(errors.CARD_ALREADY_USED);
          this.setLoading(false);
          this.disableScan = false;
          return;
        }
        const query = {
          code: encodeURIComponent(code),
          balance: response.balance,
        };
        if (!response.containsCharacter) {
          await api.useCurrencyCardAsCurrency(query.code);
          await this.loadBalance();
          this.setBalanceToppedUp(query.balance);
          this.setLoading(false);
          await this.$router.push({
            path: "home",
          });
        } else {
          this.setLoading(false);
          await this.$router.push({
            path: "choice",
            query,
          });
        }
      } catch (e) {
        this.disableScan = false;
        this.setLoading(false);
        this.setError({
          message: e?.response.data.error.message || errors.DECODE_EXCEPTION,
        });
      }
    },
  },
  created() {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      // Firefox 38+ seems having support of enumerateDevicesx
      navigator.enumerateDevices = function (callback) {
        navigator.mediaDevices.enumerateDevices().then(callback);
      };
    }

    let MediaDevices = [];
    let isHTTPs = location.protocol === "https:";
    let canEnumerate = false;

    if (
      typeof MediaStreamTrack !== "undefined" &&
      "getSources" in MediaStreamTrack
    ) {
      canEnumerate = true;
    } else if (
      navigator.mediaDevices &&
      !!navigator.mediaDevices.enumerateDevices
    ) {
      canEnumerate = true;
    }

    let hasWebcam = false;

    let isWebcamAlreadyCaptured = false;

    function checkDeviceSupport(callback) {
      if (!canEnumerate) {
        return;
      }

      if (
        !navigator.enumerateDevices &&
        window.MediaStreamTrack &&
        window.MediaStreamTrack.getSources
      ) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
          window.MediaStreamTrack
        );
      }

      if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
      }

      if (!navigator.enumerateDevices) {
        if (callback) {
          callback();
        }
        return;
      }

      MediaDevices = [];
      navigator.enumerateDevices(function (devices) {
        devices.forEach(function (_device) {
          let device = {};
          for (let d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === "audio") {
            device.kind = "audioinput";
          }

          if (device.kind === "video") {
            device.kind = "videoinput";
          }

          var skip;
          MediaDevices.forEach(function (d) {
            if (d.id === device.id && d.kind === device.kind) {
              skip = true;
            }
          });

          if (skip) {
            return;
          }

          if (!device.deviceId) {
            device.deviceId = device.id;
          }

          if (!device.id) {
            device.id = device.deviceId;
          }

          if (!device.label) {
            device.label = "Please invoke getUserMedia once.";
            if (!isHTTPs) {
              device.label =
                "HTTPs is required to get label of this " +
                device.kind +
                " device.";
            }
          } else {
            if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
              isWebcamAlreadyCaptured = true;
            }
          }

          if (device.kind === "videoinput") {
            hasWebcam = true;
          }

          // there is no 'videoouput' in the spec.

          MediaDevices.push(device);
        });

        if (callback) {
          callback();
        }
      });
    }

    checkDeviceSupport(() => {
      if (!hasWebcam) {
        this.setError(errors.DEVICE_WITHOUT_CAMERA);
        return;
      }
      if (!isWebcamAlreadyCaptured) {
        this.needCameraAccess = true;
        this.setError(errors.CAMERA_PERMISSION_DENIED);
      } else {
        this.cameraAllowed = true;
      }
    });
  },
  components: {
    PageButton,
    Close,
    GallerySvg,
    StreamBarcodeReader,
    //ImageBarcodeReader,
    QrcodeCapture,
  },
};
</script>
